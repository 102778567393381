import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a
              href="/produkty/actifed_syrop_na_katar_i_kaszel/"
              className="sel"
            >
              {" "}
              &gt; ACTIFED® SYROP NA KATAR I KASZEL
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      <span
                        style={{
                          color: "#cb0073"
                        }}
                      >
                        ACTIFED<sup>®</sup> SYROP
                        <br /> NA KATAR I KASZEL
                      </span>
                    </p>
                    <div className="desc" />
                  </div>
                  <div className="photo">
                    <span className="stamp ">
                      <img
                        src="/upload/znaczek_AZ3_v2.png"
                        alt="AZ3"
                        id="az3"
                      />
                      <img src="/upload/10h_znaczek.png" alt="10h" id="h10" />
                      <img
                        src="/upload/krople_znaczek.png"
                        alt="Zawiera kwas hialuronowy"
                        id="kh"
                      />
                      <img
                        src="/upload/zielony.png"
                        alt="Nie zawiera konserwantó"
                        id="nzk"
                      />
                    </span>
                    <img src="/web/kcfinder/uploads/files/produkty/actifed.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    W początkowych fazach{" "}
                    <a href="/slowniczek/#przeziebienie" target="_blank">
                      przeziębienia
                    </a>
                    &nbsp;oprócz zablokowanego nosa i wodnistej{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzieliny
                    </a>
                    , może także pojawić się suchy, drapiący kaszel. Actifed
                    <sup>®</sup> to lek o potrójnym działaniu. Skutecznie
                    odblokowuje nos, zmniejsza ilość wydzieliny oraz łagodzi
                    suchy kaszel. Przyjemny jeżynowy smak sprawie, że jest
                    chętnie przyjmowany przez dzieci. Złożony skład łagodzi
                    objawy początkowej fazy infekcji.&nbsp;Umożliwia spokojny
                    sen, który sprzyja regeneracji organizmu.
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <ul>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Odblokowuje nos&nbsp;
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Łagodzi suchy kaszel
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Od 7. Roku życia
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Przyjemny, jeżynowy smak
                    </li>
                  </ul>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Wskazania i dawkowanie:
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Preparat wskazany do stosowania przy leczeniu objawowym w
                    stanach zapalnych górnych dróg oddechowych
                    charakteryzujących się{" "}
                    <a
                      href="/slowniczek/#obrzek_blony_sluzowej"
                      target="_blank"
                    >
                      obrzękiem błony śluzowej
                    </a>
                    &nbsp;(blokadą) nosa i wysiękiem z nosa oraz kaszlem. &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Przeznaczony do stosowania u dorosłych oraz dzieci powyżej 7
                    roku życia. Dawkowanie preparatu uzależnione jest od wieku.
                    Dorośli oraz dzieci powyżej 12 roku życia mogą przyjmować 10
                    ml syropu trzy lub cztery razy na dobę (maksymalnie 40 ml).
                    Natomiast dzieciom w wieku od 7 do 12 lat należy podawać 5
                    ml syropu trzy lub cztery razy podczas dobry (maksymalnie 20
                    ml).
                  </div>
                  <div>&nbsp;</div>
                </div>
                <a
                  href="/web/kcfinder/uploads/files/pdf/Actifed_ChPL_14.01.2022.pdf"
                  className="pdf btn_link"
                  target="_blank"
                >
                  Przeczytaj ulotkę
                </a>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/dzieci/odpornosc_dziecka_-_zadbaj_i_zwiekszaj/">
                    <div className="c1">
                      <span className="photo" style={{"background-image":"url('/web/kcfinder/uploads/files/iStock_63844397_LARGE.png')"}} />
                     
                    </div>
                    <div className="c2">
                      Odporność dziecka - zadbaj i zwiększaj{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem/">
                    <div className="c1">
                      <span className="photo" style={{"background-image":"url('/web/kcfinder/uploads/files/artykuly/domowe_sposoby_na_walke_przezie%CC%A8bieniem.png')"}} />
                     
                    </div>
                    <div className="c2">
                      Domowe sposoby na walkę z przeziębieniem{" "}
                    </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.png" />
              </a>
              <a
                href="/produkty/actifed_syrop_na_katar_i_kaszel/"
                className="quiz_btn"
              />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
